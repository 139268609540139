import {
  AccountDocument,
  AdminExtendedPaymentIntent,
  AdminService,
  PaymentMethod,
} from '@admin-api/index';
import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { BehaviorSubject } from 'rxjs';

export interface IRequestInformationDialogReturnData {
  type: AccountDocument.TypeEnum;
  subtype: AccountDocument.SubtypeEnum;
  requestNotes?: string;
}

export interface IRequestInformationDialogData {
  accountId: string;
}

@Component({
  selector: 'app-request-information-dialog',
  templateUrl: './request-information-dialog.component.html',
  styleUrls: ['./request-information-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequestInformationDialogComponent {
  @ViewChild('stepper') stepper: MatStepper;
  public writtenSubtypes = [
    AccountDocument.SubtypeEnum.BUSINESS_MODEL,
    AccountDocument.SubtypeEnum.PRICING_MODEL,
    AccountDocument.SubtypeEnum.WEBSITE,
    AccountDocument.SubtypeEnum.SHOPPING_CART,
    AccountDocument.SubtypeEnum.OTHER,
  ];
  public documentSubtypes = [
    AccountDocument.SubtypeEnum.BANK_LETTER_VOIDED_CHECK,
    AccountDocument.SubtypeEnum.EIN_LETTER,
    AccountDocument.SubtypeEnum.ARTICLES_OF_INCORP,
    AccountDocument.SubtypeEnum.BUSINESS_LICENSE,
    AccountDocument.SubtypeEnum.UTILITY_BILL,
    AccountDocument.SubtypeEnum.PROCESSING_STATEMENT,
    AccountDocument.SubtypeEnum.FINANCIAL_STATEMENT,
    AccountDocument.SubtypeEnum.BANK_STATEMENT,
    AccountDocument.SubtypeEnum._501C3,
    AccountDocument.SubtypeEnum.TAX_RETURN,
    AccountDocument.SubtypeEnum.INVOICE,
    AccountDocument.SubtypeEnum.DL_PASSPORT,
    AccountDocument.SubtypeEnum.DL_BACK,
    AccountDocument.SubtypeEnum.OTHER,
  ];

  public writtenForm: UntypedFormGroup;
  public documentForm: UntypedFormGroup;
  public writtenRequestForm: UntypedFormGroup;
  public documentRequestForm: UntypedFormGroup;
  public showInvoiceAmount: boolean = false;
  public numberOfInvoices: number = 0;
  public invoiceControls: { control: string; label: string; index: number }[] =
    [];

  public checkedWrittenSubtypes: AccountDocument.SubtypeEnum[] = [];
  public checkedDocumentSubtypes: AccountDocument.SubtypeEnum[] = [];

  public currentStep = 0;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  public dialogTitle: string = 'Select additional information';
  public dialogSubtitle: string =
    'Request additional information from the merchant.';

  public accountId: string;
  private _invoicePaymentIntents$ = new BehaviorSubject<
    AdminExtendedPaymentIntent[]
  >([]);
  constructor(
    public matDialogRef: MatDialogRef<RequestInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: IRequestInformationDialogData,
    private fb: FormBuilder,
    private _adminService: AdminService,
    private _minorUnitsToCurrencyPipe: MinorUnitsToCurrencyPipe,
  ) {
    this.accountId = dialogData.accountId;
    this.writtenRequestForm = this.fb.group({});
    this.documentRequestForm = this.fb.group({});

    this.writtenForm = this.fb.group({
      [AccountDocument.SubtypeEnum.BUSINESS_MODEL.toString()]: [false],
      [AccountDocument.SubtypeEnum.PRICING_MODEL.toString()]: [false],
      [AccountDocument.SubtypeEnum.WEBSITE.toString()]: [false],
      [AccountDocument.SubtypeEnum.SHOPPING_CART.toString()]: [false],
      [AccountDocument.SubtypeEnum.OTHER.toString()]: [false],
    });

    this.documentForm = this.fb.group({
      [AccountDocument.SubtypeEnum.BANK_LETTER_VOIDED_CHECK.toString()]: [
        false,
      ],
      [AccountDocument.SubtypeEnum.EIN_LETTER.toString()]: [false],
      [AccountDocument.SubtypeEnum.ARTICLES_OF_INCORP.toString()]: [false],
      [AccountDocument.SubtypeEnum.BUSINESS_LICENSE.toString()]: [false],
      [AccountDocument.SubtypeEnum.UTILITY_BILL.toString()]: [false],
      [AccountDocument.SubtypeEnum.PROCESSING_STATEMENT.toString()]: [false],
      [AccountDocument.SubtypeEnum.FINANCIAL_STATEMENT.toString()]: [false],
      [AccountDocument.SubtypeEnum.BANK_STATEMENT.toString()]: [false],
      [AccountDocument.SubtypeEnum._501C3.toString()]: [false],
      [AccountDocument.SubtypeEnum.TAX_RETURN.toString()]: [false],
      [AccountDocument.SubtypeEnum.INVOICE.toString()]: [false],
      numberOfInvoices: [0, [Validators.min(0)]],
      [AccountDocument.SubtypeEnum.DL_PASSPORT.toString()]: [false],
      [AccountDocument.SubtypeEnum.DL_BACK.toString()]: [false],
      [AccountDocument.SubtypeEnum.OTHER.toString()]: [false],
    });
  }

  public cancelClicked() {
    this.matDialogRef.close(null);
  }

  public backPage() {
    this.checkedWrittenSubtypes = [];
    this.checkedDocumentSubtypes = [];
    this.writtenRequestForm = this.fb.group({});
    this.documentRequestForm = this.fb.group({});
    this.stepper.previous();
    if (this.currentStep > 0) {
      this.currentStep--;
    }
    this.getTitleAndSubtitle();
  }

  public nextPage() {
    this.writtenRequestForm.reset();
    this.documentRequestForm.reset();
    this.invoiceControls = [];
    if (
      this.documentForm.get('invoice').value &&
      this.documentForm.get('numberOfInvoices').value > 1
    ) {
      this.numberOfInvoices = this.documentForm.get('numberOfInvoices').value;
    } else {
      this.numberOfInvoices = 1;
    }

    this.writtenForm.markAllAsTouched();
    this.documentForm.markAllAsTouched();
    if (this.writtenForm.invalid || this.documentForm.invalid) {
      return;
    }

    for (const subtype of this.writtenSubtypes) {
      const control = this.writtenForm.get(subtype.toString());
      if (control && control.value) {
        this.checkedWrittenSubtypes.push(subtype);
      }
    }
    for (const subtype of this.documentSubtypes) {
      const control = this.documentForm.get(subtype.toString());
      if (control && control.value) {
        this.checkedDocumentSubtypes.push(subtype);
      }
    }

    for (const type of this.checkedWrittenSubtypes) {
      if (type === AccountDocument.SubtypeEnum.OTHER) {
        this.writtenRequestForm.addControl(
          type.toString(),
          this.fb.control('', [Validators.required]),
        );
      } else {
        this.writtenRequestForm.addControl(
          type.toString(),
          this.fb.control(''),
        );
      }
    }
    for (const type of this.checkedDocumentSubtypes) {
      if (type === AccountDocument.SubtypeEnum.OTHER) {
        this.documentRequestForm.addControl(
          type.toString(),
          this.fb.control('', [Validators.required]),
        );
      } else {
        this.documentRequestForm.addControl(
          type.toString(),
          this.fb.control(''),
        );
        if (type === AccountDocument.SubtypeEnum.INVOICE) {
          this.documentRequestForm.addControl(
            `${type.toString()}_pi`,
            this.fb.control(''),
          );
          this.invoiceControls.push(
            ...[
              {
                control: `${type.toString()}_pi`,
                label: 'Payment intent',
                index: 1,
              },
              {
                control: type.toString(),
                label: 'Invoice context',
                index: 1,
              },
            ],
          );
        }
      }

      if (
        type === AccountDocument.SubtypeEnum.INVOICE &&
        this.numberOfInvoices > 1
      ) {
        for (let i = 1; i < this.numberOfInvoices; i++) {
          const addedControls: {
            control: string;
            label: string;
            index: number;
          }[] = [];
          const controlNumber = i + 1;
          this.documentRequestForm.addControl(
            `invoice_pi${controlNumber}`,
            this.fb.control(''),
          );
          this.documentRequestForm.addControl(
            `invoice${controlNumber}`,
            this.fb.control(''),
          );

          addedControls.push({
            control: `invoice_pi${controlNumber}`,
            label: 'Payment intent',
            index: controlNumber,
          });
          addedControls.push({
            control: `invoice${controlNumber}`,
            label: 'Invoice context',
            index: controlNumber,
          });

          this.invoiceControls.push(...addedControls);
        }
      }
    }

    this.invoiceControls = this.invoiceControls.filter(
      (control, index, self) =>
        index ===
        self.findIndex(
          (t) => t.control === control.control && t.index === control.index,
        ),
    );

    this.stepper.next();
    this.currentStep++;
    this.getTitleAndSubtitle();
  }

  public submitClicked() {
    if (this.writtenRequestForm.invalid || this.documentRequestForm.invalid) {
      return;
    }

    const returnData: IRequestInformationDialogReturnData[] = [];
    const invoiceControls = {};

    for (const control of this.invoiceControls) {
      if (!invoiceControls[control.index]) {
        invoiceControls[control.index] = {};
      }
      invoiceControls[control.index][control.control] =
        this.documentRequestForm.get(control.control).value;
    }
    const paymentIntentIds = Object.values(invoiceControls).map(
      (control) =>
        control[Object.keys(control).find((key) => key.includes('_pi'))],
    );
    this.getPaymentIntentData(paymentIntentIds);

    this.isLoading$.subscribe((loading) => {
      if (!loading) {
        for (const [index, control] of Object.entries(invoiceControls)) {
          const piControl = Object.keys(control).find((key) =>
            key.includes('_pi'),
          );
          const contextControl = Object.keys(control).find(
            (key) => !key.includes('_pi'),
          );
          returnData.push({
            type: AccountDocument.TypeEnum.FILE,
            subtype: AccountDocument.SubtypeEnum.INVOICE,
            requestNotes: this.constructInvoiceNotes(
              this._invoicePaymentIntents$.value.find(
                (pi) => pi.id === control[piControl],
              ),
              control[contextControl],
            ),
          });
        }
      }
    });

    for (const [subtype, value] of Object.entries(
      this.writtenRequestForm.value,
    )) {
      returnData.push({
        type: AccountDocument.TypeEnum.WRITTEN,
        subtype: subtype as AccountDocument.SubtypeEnum,
        requestNotes: (value as string).trim(),
      });
    }
    for (const [subtype, value] of Object.entries(
      this.documentRequestForm.value,
    )) {
      // Handle invoice separately
      if (!subtype.includes('invoice')) {
        returnData.push({
          type: AccountDocument.TypeEnum.FILE,
          subtype: subtype as AccountDocument.SubtypeEnum,
          requestNotes: (value as string).trim(),
        });
      }
    }
    this.isLoading$.subscribe((loading) => {
      if (!loading) {
        this.matDialogRef.close(returnData.length > 0 ? returnData : null);
      }
    });
  }

  public mapSubtypeToTitle(subtype: AccountDocument.SubtypeEnum): string {
    switch (subtype) {
      //file
      case AccountDocument.SubtypeEnum.BANK_LETTER_VOIDED_CHECK:
        return 'Bank Letter or Voided Check';
      case AccountDocument.SubtypeEnum.EIN_LETTER:
        return 'EIN Letter';
      case AccountDocument.SubtypeEnum.ARTICLES_OF_INCORP:
        return 'Articles of Incorporation';
      case AccountDocument.SubtypeEnum.BUSINESS_LICENSE:
        return 'Business License';
      case AccountDocument.SubtypeEnum.UTILITY_BILL:
        return 'Utility Bill';
      case AccountDocument.SubtypeEnum.PROCESSING_STATEMENT:
        return 'Processing Statement (past 3 - 6 months)';
      case AccountDocument.SubtypeEnum.FINANCIAL_STATEMENT:
        return 'Financial Statement (past 1 - 2 years, include P&L Statement and Balance Sheet)';
      case AccountDocument.SubtypeEnum.BANK_STATEMENT:
        return 'Bank Statements (past 3 - 6 months)';
      case AccountDocument.SubtypeEnum._501C3:
        return '501c3';
      case AccountDocument.SubtypeEnum.TAX_RETURN:
        return 'Tax Return';
      case AccountDocument.SubtypeEnum.INVOICE:
        return 'Invoice';
      case AccountDocument.SubtypeEnum.DL_PASSPORT:
        return 'Driver’s License/Passport';
      case AccountDocument.SubtypeEnum.DL_BACK:
        return 'Driver’s License - Back side';
      //written
      case AccountDocument.SubtypeEnum.BUSINESS_MODEL:
        return 'Business model details';
      case AccountDocument.SubtypeEnum.PRICING_MODEL:
        return 'Pricing model details';
      case AccountDocument.SubtypeEnum.WEBSITE:
        return 'Website';
      case AccountDocument.SubtypeEnum.SHOPPING_CART:
        return 'No shopping cart';
      case AccountDocument.SubtypeEnum.OTHER:
        return 'Other (requires additional notes)';
      default:
        return '';
    }
  }

  public defaultRequestNoteValue(subtype: AccountDocument.SubtypeEnum): void {
    switch (subtype) {
      case AccountDocument.SubtypeEnum.TAX_RETURN:
        const priorYear = new Date().getFullYear() - 1;
        this.documentRequestForm.get(subtype).setValue(priorYear.toString());
        break;
      default:
        return;
    }
  }

  public getTitleAndSubtitle(): void {
    switch (this.currentStep) {
      case 0:
        this.dialogTitle = 'Select additional information';
        this.dialogSubtitle =
          'Request additional information from the merchant.';
        break;
      case 1:
        this.dialogTitle = 'Add a note (Optional)';
        this.dialogSubtitle =
          'Add a note to each underwriting requirement to provide additional context to the merchant about the underwriting requirements. The note will be displayed in the email notification to the merchant and in the merchant app. Leave blank if no additional context is needed.';
        break;
      case 2:
        this.dialogTitle = 'Add a note (Optional)';
        this.dialogSubtitle =
          'Add a note to each underwriting requirement to provide additional context to the merchant about the underwriting requirements. The note will be displayed in the email notification to the merchant and in the merchant app. Leave blank if no additional context is needed.';
        break;
      default:
        return;
    }
  }
  public onCheckboxChange(
    checkbox: MatCheckboxChange,
    controlName: string,
  ): void {
    if (controlName === 'invoice') {
      const numberOfInvoices = this.documentForm.get('numberOfInvoices');
      if (checkbox.checked) {
        this.showInvoiceAmount = true;
        this.documentForm.get('numberOfInvoices').setValue(1);
        numberOfInvoices.setValidators([
          Validators.required,
          Validators.min(0),
        ]);
        numberOfInvoices.setValue(null);
        numberOfInvoices.updateValueAndValidity();
      } else {
        numberOfInvoices.clearValidators();
        numberOfInvoices.setValue(null);
        numberOfInvoices.updateValueAndValidity();
        this.showInvoiceAmount = false;
        this.invoiceControls = [];
      }
    }
  }

  public constructInvoiceNotes(
    paymentIntent: AdminExtendedPaymentIntent,
    context: string,
  ): string {
    let notes = '';

    const dateFormat: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: true,
      minute: '2-digit',
      hour: 'numeric',
      second: '2-digit',
    };

    if (paymentIntent) {
      notes += `Invoice Amount: ${this._minorUnitsToCurrencyPipe.transform(
        paymentIntent.amount,
        paymentIntent.currency,
      )} | Date: ${new Date(paymentIntent.created_at).toLocaleString(
        'en-US',
        dateFormat,
      )} | Cardholder Name: ${this.getCardHolderName(
        paymentIntent,
      )} | Payment ID: ${paymentIntent.id}`;
    }
    if (context) {
      notes += paymentIntent
        ? ` | Additional notes: ${context}`
        : `Additional notes: ${context}`;
    }
    return notes;
  }

  private getPaymentIntentData(paymentIntentIds: string[]): void {
    if (paymentIntentIds?.length === 0) {
      return;
    }
    this.isLoading$.next(true);
    this._adminService
      .adminListPaymentIntents({
        accountId: this.accountId,
        ids: paymentIntentIds,
      })
      .subscribe({
        next: (res) => {
          this._invoicePaymentIntents$.next(res.items);
        },
        error: (err) => {
          this._invoicePaymentIntents$.next([]);
        },
        complete: () => {
          this.isLoading$.next(false);
        },
      });
  }
  private getCardHolderName(payment: AdminExtendedPaymentIntent): string {
    if (payment.payment_method?.type === PaymentMethod.TypeEnum.CARD) {
      return payment.payment_method?.card
        ? payment.payment_method?.card?.holder_name
          ? payment.payment_method?.card?.holder_name
          : '-'
        : payment.payment_method?.billing_details?.name
        ? payment.payment_method?.billing_details?.name
        : '-';
    } else if (
      payment.payment_method?.type === PaymentMethod.TypeEnum.CARD_PRESENT
    ) {
      return payment.payment_method?.card_present
        ? payment.payment_method?.card_present?.holder_name
          ? payment.payment_method?.card_present?.holder_name
          : '-'
        : payment.payment_method?.billing_details?.name
        ? payment.payment_method?.billing_details?.name
        : '-';
    }
    return payment.payment_method?.billing_details?.name
      ? payment.payment_method?.billing_details?.name
      : '-';
  }
}
