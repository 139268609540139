import { AdminService, CreateStatementRequestParams, Statement } from '@admin-api/index';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

const TWENTY_DAYS = 1_000 * 60 * 60 * 24 * 20;

@Component({
  selector: 'upload-commission-statement',
  templateUrl: './upload-commission-statement-dialog.component.html',
  styleUrls: ['./upload-commission-statement-dialog.component.scss'],
})
export class UploadCommissionStatementDialogComponent {
  private accountId: string;
  uploadStatementForm: UntypedFormGroup;
  uploadStatementParameters: CreateStatementRequestParams;
  isLoading = false;
  uploadComplete = true;
  fileToUpload = null;
  periods: string[] = [];
  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor(
    public matDialogRef: MatDialogRef<UploadCommissionStatementDialogComponent>,
    private readonly _adminService: AdminService,
    private readonly _snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      accountId: string;
    },
  ) {
    this.accountId = _data.accountId;
    this.uploadStatementParameters = {
      accountId: this._data.accountId,
      amount: 0,
      file: null,
      paymentLink: null,
      period: null,
      type: 'commission',
    };
    let period = new Date();
    period.setDate(1);
    this.periods.push(this.createPeriod(new Date(period.setMonth(period.getMonth() - 2))));
    this.periods.push(this.createPeriod(new Date(period.setMonth(period.getMonth() + 1))));
    this.periods.push(this.createPeriod(new Date(period.setMonth(period.getMonth() + 1))))
    this.periods.push(this.createPeriod(new Date(period.setMonth(period.getMonth() + 1))));
    this.periods.push(this.createPeriod(new Date(period.setMonth(period.getMonth() + 1))));
    this.uploadStatementForm = this.UploadStatementForm();
  }
 
  UploadStatementForm(): UntypedFormGroup {
    return this._formBuilder.group({
      amount: [0, Validators.required],
      file: [this.uploadStatementParameters.file, Validators.required],
      paymentLink: [this.uploadStatementParameters.paymentLink],
      period: [this.uploadStatementParameters.period, Validators.required],
    });
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    this.uploadStatementForm.setValue({
      ...this.uploadStatementForm.value,
      file: this.fileToUpload,
    });
  }

  onSelectionChange(event) {
    const periodSelection = event;
    this.uploadStatementForm.setValue({
      ...this.uploadStatementForm.value,
      period: periodSelection,
    });
  }

  upload() {
    this.isLoading = true;
    this.uploadStatementParameters.accountId = this.accountId;
    this.uploadStatementParameters.type = Statement.TypeEnum.COMMISSION;
    this.uploadStatementParameters.amount = this.uploadStatementForm.value.amount;
    this.uploadStatementParameters.period =  this.uploadStatementForm.value.period;
    this.uploadStatementParameters.paymentLink = this.uploadStatementForm.value.paymentLink ? this.uploadStatementForm.value.paymentLink : undefined;
    this.uploadStatementParameters.file =
      this.uploadStatementForm.value.file;
    this._adminService
      .createStatement(this.uploadStatementParameters)
      .subscribe(
        (res) => {
          this._snackBar.open('Upload successful', null, {
            duration: 3000,
          });
          this.matDialogRef.close(true);
        },
        (error) => {
          this.isLoading = false;
          this._snackBar.open(
            `Unable to submit: ${error.error.message}`,
            null,
            {
              duration: 3000,
            },
          );
        },
      );
  }

  private showError(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 5000,
      verticalPosition: 'top',
    });
  }

  private createPeriod(date: Date): string {
    return this.months[date.getMonth()] + " " + date.getFullYear();
  }
}
