import { AdminExtendedCharge } from '@admin-api/index';
import { AdminExtendedPaymentIntent } from '@admin-api/model/adminExtendedPaymentIntent';
import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PaymentStatusComponent implements OnChanges {
  @Input() payment: AdminExtendedPaymentIntent;

  public displayStatus: string;
  public colorClass: 'gray' | 'red' | 'green' = 'gray';
  public icon: 'done' | 'undo' | 'close' | 'info' = null;
  public tooltipText: string;
  public isExternal: boolean = false;

  constructor(private _currencyPipe: CurrencyPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'payment') {
        this.computeDisplayStatus();
      }
    }
  }

  private computeDisplayStatus(): void {
    // always reset default values
    this.displayStatus = undefined;
    this.colorClass = 'gray';
    this.icon = null;
    this.tooltipText = undefined;
    this.isExternal =
      this.payment.charges[0]?.source_type ===
      AdminExtendedCharge.SourceTypeEnum.EXTERNAL;

    if (this.payment.status === 'succeeded') {
      const successfulCharge = this.payment.charges.find(
        (c) => c.status === 'succeeded',
      );
      if (successfulCharge.refunded) {
        this.displayStatus = 'Refunded';
        this.icon = 'undo';
        this.tooltipText = 'The payment has been fully refunded.';
      } else if (successfulCharge.amount_refunded > 0) {
        this.displayStatus = 'Partial refund';
        const refundAmountText = this._currencyPipe.transform(
          successfulCharge.amount_refunded / 100,
        );
        this.tooltipText = `${refundAmountText} was refunded.`;
        this.icon = 'info';
        this.colorClass = 'gray';
      } else if (
        successfulCharge.amount_captured < this.payment.amount &&
        successfulCharge.amount_refunded === 0 &&
        this.payment.capture_method ===
          AdminExtendedPaymentIntent.CaptureMethodEnum.AUTOMATIC
      ) {
        this.displayStatus = 'Succeeded/Partial auth';
        const captureAmountText = this._currencyPipe.transform(
          successfulCharge.amount_captured / 100,
        );
        this.tooltipText = `${captureAmountText} was captured.`;
        this.icon = 'info';
        this.colorClass = 'green';
      } else {
        this.displayStatus = 'Succeeded';
        this.colorClass = 'green';
        this.icon = 'done';
        this.tooltipText = 'This payment is complete.';
      }
    } else if (this.payment.status === 'requires_payment_method') {
      if (this.payment.charges.length > 0) {
        this.displayStatus = 'Failed';
        this.colorClass = 'red';
        this.icon = 'close';
        this.tooltipText = this.payment.last_payment_error?.message;
      } else {
        this.displayStatus = 'Incomplete';
        this.tooltipText = 'The payment method is required.';
      }
    } else if (this.payment.status === 'processing') {
      this.displayStatus = 'Processing';
    } else if (this.payment.status === 'canceled' && this.isExternal) {
      this.displayStatus = 'Failed';
    } else if (this.payment.status === 'canceled' && !this.isExternal) {
      this.displayStatus = 'Canceled';
    } else if (this.payment.status === 'requires_capture') {
      this.displayStatus = 'Uncaptured';
    } else {
      this.displayStatus = this.payment.status;
    }
  }
}
