/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminDashboardGrossVolumeDataPointDetail { 
    /**
     * Total number of charges affecting gross volume
     */
    charge_count: number;
    currency: AdminDashboardGrossVolumeDataPointDetail.CurrencyEnum;
    /**
     * In currency minor units
     */
    gross_volume: number;
    payment_method_type: AdminDashboardGrossVolumeDataPointDetail.PaymentMethodTypeEnum;
}
export namespace AdminDashboardGrossVolumeDataPointDetail {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type PaymentMethodTypeEnum = 'card' | 'ach_debit' | 'card_present' | 'eft_debit';
    export const PaymentMethodTypeEnum = {
        CARD: 'card' as PaymentMethodTypeEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypeEnum,
        CARD_PRESENT: 'card_present' as PaymentMethodTypeEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypeEnum
    };
}


