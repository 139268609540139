/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProcessingCapability { 
    provider: ProcessingCapability.ProviderEnum;
}
export namespace ProcessingCapability {
    export type ProviderEnum = 'paysafe' | 'tsys' | 'valor' | 'handpoint' | 'north' | 'multi_pass';
    export const ProviderEnum = {
        PAYSAFE: 'paysafe' as ProviderEnum,
        TSYS: 'tsys' as ProviderEnum,
        VALOR: 'valor' as ProviderEnum,
        HANDPOINT: 'handpoint' as ProviderEnum,
        NORTH: 'north' as ProviderEnum,
        MULTI_PASS: 'multi_pass' as ProviderEnum
    };
}


