<div class="overflow-scroll p-3 max-h-[80vh] hide-scrollbars max-w-[36.5rem]">
  <div class="flex justify-between items-stretch">
    <div class="space-y-4 pt-3">
      <div class="flex flex-wrap justify-start">
        <p class="font-semibold leading-none text-h2 text-tilled-primary">
          {{ dialogTitle }}
        </p>
      </div>
      <div class="flex flex-col leading-snug space-y-4">
        <span class="">
          {{ dialogSubtitle }}
        </span>
        <span
          *ngIf="currentStep === 1"
          class="italic font-semibold text-sm text-warn"
        >
          Requesting information updates the application status to 'Action
          Required' and preserves all existing inputs.
        </span>
      </div>
    </div>
    <div class="mat-icon-container">
      <mat-icon
        data-test-id="form-card-exit-button"
        style="cursor: pointer"
        (click)="matDialogRef.close()"
        svgIcon="mat_outline:close"
        class="text-tilled-primary"
      ></mat-icon>
    </div>
  </div>

  <div class="my-3" [ngClass]="{ hidden: (isLoading$ | async) }">
    <mat-stepper #stepper cdk-scrollable labelPosition="bottom">
      <!-- Document request type selections step -->
      <mat-step label="First Step" color="primary" class="overflow-y-auto">
        <div class="flex flex-row items-center py-2">
          <mat-divider class="w-full"></mat-divider>
          <p
            class="self-center whitespace-nowrap px-2 font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
          >
            Written Prompts
          </p>
          <mat-divider class="w-full"></mat-divider>
        </div>
        <form class="px-4" [formGroup]="writtenForm">
          <div *ngFor="let type of writtenSubtypes">
            <mat-checkbox class="py-1" [formControlName]="type.toString()">
              <span class="text-base tracking-normal text-tilled-primary">
                {{ mapSubtypeToTitle(type) }}
              </span>
            </mat-checkbox>
          </div>
        </form>
        <div class="flex flex-row items-center py-2">
          <mat-divider class="w-full"></mat-divider>
          <p
            class="self-center whitespace-nowrap px-2 font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
          >
            Business Documents
          </p>
          <mat-divider class="w-full"></mat-divider>
        </div>
        <form class="px-4" [formGroup]="documentForm">
          <div
            *ngFor="let type of documentSubtypes"
            [ngClass]="{
              'flex flex-row flex-nowrap justify-start border-y my-1 gap-4 flex-1 divide-x overflow-clip':
                type.toString() === 'invoice' && showInvoiceAmount
            }"
          >
            <mat-checkbox
              class="py-1"
              [ngClass]="{
                'items-center flex mb-6':
                  type.toString() === 'invoice' && showInvoiceAmount
              }"
              [formControlName]="type.toString()"
              (change)="onCheckboxChange($event, type.toString())"
            >
              <span class="text-base tracking-normal text-tilled-primary">
                {{ mapSubtypeToTitle(type) }}
              </span>
            </mat-checkbox>
            <ng-container
              *ngIf="type.toString() === 'invoice' && showInvoiceAmount"
            >
              <mat-form-field class="pt-2 -mb-2.5 w-full pl-5">
                <input
                  matInput
                  placeholder="How many invoices should the merchant provide?"
                  formControlName="numberOfInvoices"
                  type="number"
                  min="0"
                  step="1"
                />
                <mat-error
                  color="warn"
                  *ngIf="
                    documentForm.get('numberOfInvoices').errors &&
                    documentForm.get('numberOfInvoices').touched
                  "
                  class="pt-2 text-sm mt-0 font-normal leading-tight"
                >
                  If <b>Invoice</b> is selected, a number of invoices is
                  required.
                </mat-error>
                <mat-hint class="text-sm font-normal leading-tight pt-1">
                  This is the number of invoices you are requesting from the
                  merchant.
                </mat-hint>
              </mat-form-field>
            </ng-container>
          </div>
        </form>

        <div class="flex w-full justify-end">
          <!-- Cancel button -->
          <button class="mr-4" mat-button (click)="cancelClicked()">
            Cancel
          </button>
          <!-- Open application button -->
          <button mat-flat-button color="primary" (click)="nextPage()">
            <span>Next</span>
          </button>
        </div>
      </mat-step>

      <!-- Request notes and submit step -->
      <mat-step label="Second Step" color="primary" class="overflow-y-auto">
        <ng-container *ngIf="checkedWrittenSubtypes?.length > 0">
          <div class="flex flex-row items-center py-2">
            <mat-divider class="w-full"></mat-divider>
            <p
              class="self-center whitespace-nowrap px-2 font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
            >
              Written Prompts
            </p>
            <mat-divider class="w-full"></mat-divider>
          </div>
          <form
            [formGroup]="writtenRequestForm"
            *ngIf="checkedWrittenSubtypes?.length > 0"
          >
            <div *ngFor="let type of checkedWrittenSubtypes">
              <span
                class="tracking-normal text-tilled-primary self-center font-medium flex pb-1.5"
              >
                {{ mapSubtypeToTitle(type) }}
              </span>
              <mat-form-field class="w-full">
                <input
                  matInput
                  [formControlName]="type.toString()"
                  maxLength="255"
                />
              </mat-form-field>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="checkedDocumentSubtypes?.length > 0">
          <div class="flex flex-row items-center py-2">
            <mat-divider class="w-full"></mat-divider>
            <p
              class="self-center whitespace-nowrap px-2 font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
            >
              Business Documents
            </p>
            <mat-divider class="w-full"></mat-divider>
          </div>
          <form [formGroup]="documentRequestForm">
            <div *ngFor="let type of checkedDocumentSubtypes">
              <ng-container
                *ngIf="!(type.toString() === 'invoice' && numberOfInvoices > 1)"
              >
                <ng-container *ngIf="type.toString() === 'invoice'">
                  <div class="flex flex-row items-center py-2">
                    <mat-divider class="w-full"></mat-divider>
                    <p
                      class="self-center whitespace-nowrap px-2 text-md font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
                    >
                      Invoice
                    </p>
                    <mat-divider class="w-full"></mat-divider>
                  </div>
                  <span
                    class="tracking-normal text-tilled-primary self-center font-medium flex pb-1.5"
                  >
                    Payment intent ID
                  </span>
                  <mat-form-field class="w-full">
                    <input
                      matInput
                      [formControlName]="type.toString() + '_pi'"
                      maxLength="255"
                      value="{{ defaultRequestNoteValue(type) }}"
                    />
                    <mat-hint class="text-sm font-normal leading-tight pt-1">
                      The Payment intent ID associated with the invoice.
                    </mat-hint>
                  </mat-form-field>
                </ng-container>
                <span
                  class="tracking-normal text-tilled-primary self-center font-medium flex pb-1.5"
                >
                  {{
                    type.toString() === 'invoice'
                      ? 'Invoice context'
                      : mapSubtypeToTitle(type)
                  }}
                </span>
                <mat-form-field class="w-full">
                  <input
                    matInput
                    [formControlName]="type.toString()"
                    maxLength="255"
                    value="{{ defaultRequestNoteValue(type) }}"
                  />
                  <mat-hint
                    *ngIf="type.toString() === 'invoice'"
                    class="text-sm font-normal leading-tight pt-1"
                  >
                    This is any additional context surrounding the specific
                    invoice request.
                  </mat-hint>
                </mat-form-field>
                <mat-divider
                  *ngIf="type.toString() === 'invoice'"
                  class="w-full pb-4"
                ></mat-divider>
              </ng-container>
            </div>
            <ng-container *ngIf="numberOfInvoices > 1">
              <ng-container
                *ngFor="let control of invoiceControls; let i = index"
              >
                <div
                  class="flex flex-row items-center py-2"
                  *ngIf="control.label === 'Payment intent'"
                >
                  <mat-divider class="w-full"></mat-divider>
                  <p
                    class="self-center whitespace-nowrap px-2 text-md font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
                  >
                    {{
                      'Invoice' +
                        (control.index === 0 ? '' : ' #' + control.index)
                    }}
                  </p>
                  <mat-divider class="w-full"></mat-divider>
                </div>
                <span
                  class="tracking-normal text-tilled-primary self-center font-medium flex pb-1.5"
                >
                  {{ control.label }}
                </span>
                <mat-form-field class="w-full">
                  <input
                    matInput
                    [formControlName]="control.control"
                    maxLength="255"
                    value="{{ defaultRequestNoteValue(type) }}"
                  />
                </mat-form-field>
              </ng-container>
            </ng-container>
          </form>
        </ng-container>
        <div class="flex w-full justify-end">
          <!-- Cancel button -->
          <button class="mr-4" mat-button (click)="backPage()">Back</button>
          <!-- Open application button -->
          <button mat-flat-button color="primary" (click)="submitClicked()">
            <span>Submit Request</span>
          </button>
        </div>
      </mat-step>
      <mat-step label="Second Step" color="primary"> </mat-step>
    </mat-stepper>
  </div>
  <ng-container *ngIf="isLoading$ | async">
    <div>
      <mat-progress-spinner
        [diameter]="24"
        [mode]="'indeterminate'"
      ></mat-progress-spinner>
    </div>
  </ng-container>
</div>
