/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Statement { 
    /**
     * ID of the Tilled account for this statement.
     */
    account_id: string;
    /**
     * The amount of the statement
     */
    amount: number;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * When the statement is deleted.
     */
    deleted_at?: string;
    /**
     * The file associated with this statement.
     */
    file: any;
    /**
     * The id of associated file
     */
    file_id: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Optional payment link
     */
    payment_link?: string;
    /**
     * The time period of the uploaded document
     */
    period: string;
    /**
     * The type of statement
     */
    type: Statement.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace Statement {
    export type TypeEnum = 'commission';
    export const TypeEnum = {
        COMMISSION: 'commission' as TypeEnum
    };
}


