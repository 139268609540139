/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BankAccountVerificationHistory { 
    /**
     * Time at which the verification entered this status.
     */
    verification_date: string;
    /**
     * Verification history of bank account during onboarding primarily.
     */
    verification_method: BankAccountVerificationHistory.VerificationMethodEnum;
    /**
     * The bank account status as a result of this verification.
     */
    verification_status: BankAccountVerificationHistory.VerificationStatusEnum;
    verification_user_id?: string;
}
export namespace BankAccountVerificationHistory {
    export type VerificationMethodEnum = 'plaid' | 'paysafe' | 'tilled_manual';
    export const VerificationMethodEnum = {
        PLAID: 'plaid' as VerificationMethodEnum,
        PAYSAFE: 'paysafe' as VerificationMethodEnum,
        TILLED_MANUAL: 'tilled_manual' as VerificationMethodEnum
    };
    export type VerificationStatusEnum = 'new' | 'validated' | 'verified' | 'verification_failed' | 'errored';
    export const VerificationStatusEnum = {
        NEW: 'new' as VerificationStatusEnum,
        VALIDATED: 'validated' as VerificationStatusEnum,
        VERIFIED: 'verified' as VerificationStatusEnum,
        VERIFICATION_FAILED: 'verification_failed' as VerificationStatusEnum,
        ERRORED: 'errored' as VerificationStatusEnum
    };
}


