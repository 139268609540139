/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerifyUserResponse { 
    /**
     * The status of the user verification.
     */
    status: VerifyUserResponse.StatusEnum;
    /**
     * The email or phone number of the user.
     */
    user_contact: string;
}
export namespace VerifyUserResponse {
    export type StatusEnum = 'pending' | 'successful' | 'failed';
    export const StatusEnum = {
        PENDING: 'pending' as StatusEnum,
        SUCCESSFUL: 'successful' as StatusEnum,
        FAILED: 'failed' as StatusEnum
    };
}


