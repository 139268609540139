/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CommissionReportType = 'ledgers' | 'transactions';

export const CommissionReportType = {
    LEDGERS: 'ledgers' as CommissionReportType,
    TRANSACTIONS: 'transactions' as CommissionReportType
};

