import {
  AdminExtendedAccount,
  AdminNote,
  AdminService,
} from '@admin-api/index';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AddNoteDialogComponent } from '../add-note-dialog/add-note-dialog.component';

export class NoteViewModel {
  note: string;
  admin_user: string;
  submitted_on: string;
}

@Component({
  selector: 'notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotesDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) private _paginator: MatPaginator;
  public addNoteDialogRef: MatDialogRef<AddNoteDialogComponent>;
  data: any;
  account: AdminExtendedAccount;
  sourceType: string;
  sourceId: string;
  noNotes: boolean = true;
  notesCount: number = 0;
  isLoading: boolean = true;

  public notesTableColumns: string[] = ['note'];

  public pageSizeOptions = [10, 20, 30];
  public dataSource = new MatTableDataSource<NoteViewModel>();
  errorMessage: string = null;

  constructor(
    public matDialogRef: MatDialogRef<NotesDialogComponent>,
    private _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _adminService: AdminService,
    private _snackBar: MatSnackBar,
  ) {
    this.data = _data;
    this.account = this.data['account'];
    this.sourceId = this.data['sourceId'];
    this.sourceType = this.data['sourceType'];
  }

  ngOnInit(): void {
    this.dataSource.paginator = this._paginator;
    this.retrieveFilteredNotes();
  }

  ngAfterViewInit(): void {
    this._paginator.page.subscribe(() => {
      this.retrieveFilteredNotes();
    });
  }

  ngOnDestroy(): void {}

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  retrieveFilteredNotes() {
    let offset = 0;
    let limit = 10;
    if (this._paginator) {
      offset = this._paginator.pageIndex * this._paginator.pageSize;
      limit = this._paginator.pageSize;
    }

    this.retrieveNotes(this.account.id, this.data['sourceId'], offset, limit);
  }

  retrieveNotes(
    accountId: string,
    sourceId: string,
    offset = 0,
    limit = 10,
  ): void {
    this._adminService
      .adminListNotes({
        accountId,
        sourceType: 'account', //only ENUM supported for now
        sourceId,
        offset,
        limit,
      })
      .subscribe(
        (res) => {
          this.dataSource.data = this.noteDataToViewModel(res.items);
          this.notesCount = res.total;
          if (this.notesCount > 0) {
            this.noNotes = false;
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error?.error?.message) {
            this.errorMessage = 'Error: ' + error.error.message;
          } else if (error?.message) {
            this.errorMessage = 'Error: ' + error.message;
          } else {
            this.errorMessage = 'Unknown error';
          }
          this.showError(this.errorMessage);
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  private noteDataToViewModel(notes: AdminNote[]): NoteViewModel[] {
    let viewModel: NoteViewModel[] = [];

    for (const note of notes) {
      viewModel.push({
        note: note.note,
        admin_user: note.admin_user_id,
        submitted_on: note.created_at,
      });
    }
    return viewModel;
  }

  private showError(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 5000,
      verticalPosition: 'top',
    });
  }

  addNote() {
    this.addNoteDialogRef = this._matDialog.open(AddNoteDialogComponent, {
      panelClass: 'add-note-dialog',
      data: {
        account: this.account,
        sourceId: this.account.id,
        sourceType: this.sourceType,
      },
    });
    this.addNoteDialogRef.afterClosed().subscribe((res: UntypedFormGroup) => {
      this.retrieveFilteredNotes();
    });
  }
}
