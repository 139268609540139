/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminCardBuyRateCreateParams { 
    account_monthly_fee: number;
    account_monthly_minimum_fee: number;
    account_setup_fee: number;
    active_on: string;
    bank_account_change_fee: number;
    card_type_group: AdminCardBuyRateCreateParams.CardTypeGroupEnum;
    card_update_fee: number;
    chargeback_fee: number;
    currency: AdminCardBuyRateCreateParams.CurrencyEnum;
    discount_rate: number;
    discount_type: AdminCardBuyRateCreateParams.DiscountTypeEnum;
    max_volume?: number;
    min_volume: number;
    monthly_terminal_fee: number;
    non_standard: boolean;
    provider_account_id?: string;
    retrieval_fee: number;
    reversal_fee: number;
    transaction_fee: number;
    type: AdminCardBuyRateCreateParams.TypeEnum;
    verified_at?: string;
}
export namespace AdminCardBuyRateCreateParams {
    export type CardTypeGroupEnum = 'amex' | 'non_amex';
    export const CardTypeGroupEnum = {
        AMEX: 'amex' as CardTypeGroupEnum,
        NON_AMEX: 'non_amex' as CardTypeGroupEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type DiscountTypeEnum = 'flat' | 'cost_plus';
    export const DiscountTypeEnum = {
        FLAT: 'flat' as DiscountTypeEnum,
        COST_PLUS: 'cost_plus' as DiscountTypeEnum
    };
    export type TypeEnum = 'card';
    export const TypeEnum = {
        CARD: 'card' as TypeEnum
    };
}


