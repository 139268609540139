/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BankVerification { 
    bank_account_id?: string;
    verification_method?: BankVerification.VerificationMethodEnum;
    verification_status?: BankVerification.VerificationStatusEnum;
}
export namespace BankVerification {
    export type VerificationMethodEnum = 'plaid' | 'paysafe' | 'tilled_manual';
    export const VerificationMethodEnum = {
        PLAID: 'plaid' as VerificationMethodEnum,
        PAYSAFE: 'paysafe' as VerificationMethodEnum,
        TILLED_MANUAL: 'tilled_manual' as VerificationMethodEnum
    };
    export type VerificationStatusEnum = 'new' | 'validated' | 'verified' | 'verification_failed' | 'errored';
    export const VerificationStatusEnum = {
        NEW: 'new' as VerificationStatusEnum,
        VALIDATED: 'validated' as VerificationStatusEnum,
        VERIFIED: 'verified' as VerificationStatusEnum,
        VERIFICATION_FAILED: 'verification_failed' as VerificationStatusEnum,
        ERRORED: 'errored' as VerificationStatusEnum
    };
}


