/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MarkupCreateParams } from './markupCreateParams';


export interface CardChargeFeeTemplateCreateParams { 
    /**
     * The amount of the fee applied for bank account updates, in currency minor units.
     */
    bank_account_change_fee?: number;
    /**
     * The amount of the fee applied per card updated, in currency minor units.
     */
    card_update_fee?: number;
    /**
     * The amount of the fee applied per chargeback, in currency minor units.
     */
    chargeback_fee: number;
    /**
     * The list of additional markups applied to a given card type.
     */
    markups: Array<MarkupCreateParams>;
    /**
     * The amount of the monthly fee applied per terminal, in currency minor units.
     */
    monthly_terminal_fee?: number;
    /**
     * Pass through certain fees to merchants on flat rate billing.  NOTE: Hide this property from docs if this ends up being exposed in the public API
     */
    pass_through_fees?: boolean;
    /**
     * The amount of the fee applied per retrieval, in currency minor units.
     */
    retrieval_fee: number;
    /**
     * The amount of the fee applied per reversal, in currency minor units.
     */
    reversal_fee: number;
    /**
     * The amount of the transaction fee applied to each transaction, in currency minor units.
     */
    transaction_fee: number;
    /**
     * The type of transaction fee this pricing template applies.
     */
    transaction_fee_type: CardChargeFeeTemplateCreateParams.TransactionFeeTypeEnum;
}
export namespace CardChargeFeeTemplateCreateParams {
    export type TransactionFeeTypeEnum = 'flat_rate' | 'interchange';
    export const TransactionFeeTypeEnum = {
        FLAT_RATE: 'flat_rate' as TransactionFeeTypeEnum,
        INTERCHANGE: 'interchange' as TransactionFeeTypeEnum
    };
}


