<div
  class="inset-0 flex flex-col min-w-0 overflow-hidden bg-card dark:bg-transparent"
>
  <!-- Header -->
  <div
    class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b"
  >
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- Title -->
    <div class="text-3xl font-extrabold tracking-tight">Commission Statements</div>

    <!-- Actions -->
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
      <!-- Upload -->
      <button
              class=""
              mat-flat-button
              [color]="'primary'"
              (click)="uploadCommissionStatementDialog()"
              [permission]="'statement:write'"
            >
              <span class="ml-2 mr-1">Upload Statement</span>
            </button>
    </div>
  </div>

  <!-- Main -->
  <div class="flex flex-auto">
    <!-- Statements list -->
    <div class="flex-col sm:mb-18">
      <ng-container *ngIf="statementsCount > 0; else noStatements">
        <!-- Table wrapper -->
        <div class="overflow-x-auto sm:overflow-y-auto" cdkScrollable>
          <!-- Table -->
          <table
            class="w-full min-w-320 table-fixed bg-transparent"
            [ngClass]="{ 'pointer-events-none': isLoading }"
            mat-table
            [multiTemplateDataRows]="false"
            [dataSource]="dataSource"
            [trackBy]="trackByFn"
          >
            <ng-container matColumnDef="period">
              <th
                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Statement
              </th>
              <td class="pr-8 truncate" mat-cell *matCellDef="let statement">
                <div class="font-bold">
                  {{ statement.period }}
                </div>
              </td>
            </ng-container>

            <!-- Last Request At -->
            <ng-container matColumnDef="created_at">
              <th
                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Uploaded At
              </th>
              <td class="pr-4" mat-cell *matCellDef="let statement">
                {{ statement.created_at }}
              </td>
            </ng-container>

            <!-- Amount -->
            <ng-container matColumnDef="amount">
              <th
                class="w-56 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Amount
              </th>
              <td class="pr-4" mat-cell *matCellDef="let statement">
                {{ statement.amount * 100 | minorUnitsToCurrency  }}
              </td>
            </ng-container>
            <!-- Amount -->
            <ng-container matColumnDef="link">
              <th
                class="w-56 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              >
                Payment Link
              </th>
              <td class="pr-4" mat-cell *matCellDef="let statement">
                <button
                    mat-raised-button
                    *ngIf="statement.payment_link"
                    matTooltip="For negative commissions, copy and share the payment link with the partner"
                    [cdkCopyToClipboard]="statement.payment_link"
                    (click)="
                      $event.stopPropagation();
                      openSnackBar(
                        'Commission statement payment link copied to clipboard',
                        'dismiss'
                      )
                    "
                  >
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:link'"
                  ></mat-icon>
                </button>
              </td>

            </ng-container>
            <!-- Details -->
            <ng-container matColumnDef="download">
              <th
                class="w-24 pr-8 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                mat-header-cell
                *matHeaderCellDef
              ></th>
              <td class="pr-8" mat-cell *matCellDef="let statement">
                <button
                  class="ml-auto -mr-4"
                  mat-icon-button
                  (click)="downloadStatement(statement.file_id)"
                >
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:download'"
                  ></mat-icon>
                </button>

              </td>
            </ng-container>

            <tr
              class="shadow"
              mat-header-row
              *matHeaderRowDef="statementsTableColumns; sticky: true"
            ></tr>
            <tr
              class="h-18 hover:bg-gray-100 dark:hover:bg-hover"
              mat-row
              *matRowDef="let statement; columns: statementsTableColumns"
            ></tr>
          </table>
        </div>
      </ng-container>

      <mat-paginator
        class="sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
        [ngClass]="{ 'pointer-events-none': isLoading }"
        [length]="statementsCount"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        [hidden]="statementsCount === 0"
      ></mat-paginator>

      <ng-template #noStatements>
        <div
          *ngIf="!isLoading"
          class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center"
        >
          There are no statements
          <div class="text-red-500 text-base" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
        <div
          *ngIf="isLoading"
          class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center"
        >
          Loading...
        </div>
      </ng-template>
    </div>
  </div>
</div>
