<div class="flex flex-col max-w-200 min-w-200 max-h-screen -m-6">
  <mat-toolbar matDialogTitle class="mat-primary m-0">
    <mat-toolbar-row class="flex">
      <span class="dialog-title flex-1 truncate">Upload Commission Statement</span>
      <button
        class="close-icon"
        mat-icon-button
        (click)="matDialogRef.close(false)"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form class="flex flex-col flex-auto p-8 overflow-y-auto"
    [formGroup]="uploadStatementForm"
  >
  <fieldset class="flex flex-col flex-auto" [disabled]="isLoading">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Commission period</mat-label>
      <mat-select
        (selectionChange)="onSelectionChange($event.value)"
        required
      >
      <mat-option
      *ngFor="let period of periods"
      [value]="period"
      >{{ period }}</mat-option
    >
      </mat-select>
      <mat-error>required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Commission amount</mat-label>
      <input matInput formControlName="amount" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Payment link</mat-label>
      <input matInput formControlName="paymentLink" />
    </mat-form-field>
    </fieldset>
    <fieldset class="flex flex-col flex-auto" [disabled]="isLoading">
      <div>
        <p class="font-bold">Commission Statement:</p>
        <p *ngIf="fileToUpload">{{ fileToUpload.name }}</p>
        <p *ngIf="!fileToUpload">No file selected.</p>
      </div>
      <input
        class="custom-file-input"
        type="file"
        (change)="onFileChange($event)"
        #file
      />
      <button
        mat-flat-button
        color="primary"
        (click)="file.click()"
        *ngIf="!isLoading"
      >
        {{ fileToUpload ? 'Select Another File' : 'Select File' }}
      </button>
      <button mat-flat-button color="primary" disabled="true" *ngIf="isLoading">
        Saving
      </button>
    </fieldset>

    <mat-divider class="mt-6"></mat-divider>

    <div class="flex mt-6">
      <!-- Send -->
      <button
        class="ml-auto"
        mat-flat-button
        [color]="'primary'"
        (click)="upload()"
        [disabled]="uploadStatementForm.invalid || isLoading"
      >
      Upload Statement
      </button>
    </div>
  </form>
</div>