/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminDirectDebitBuyRateCreateParams { 
    account_monthly_fee: number;
    account_monthly_minimum_fee: number;
    account_setup_fee: number;
    active_on: string;
    currency: AdminDirectDebitBuyRateCreateParams.CurrencyEnum;
    non_standard: boolean;
    provider_account_id?: string;
    return_fee: number;
    transaction_fee: number;
    type: AdminDirectDebitBuyRateCreateParams.TypeEnum;
    verified_at: string;
}
export namespace AdminDirectDebitBuyRateCreateParams {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type TypeEnum = 'ach_debit' | 'eft_debit';
    export const TypeEnum = {
        ACH_DEBIT: 'ach_debit' as TypeEnum,
        EFT_DEBIT: 'eft_debit' as TypeEnum
    };
}


