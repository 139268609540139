/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TerminalReaderSettings } from './terminalReaderSettings';


export interface TerminalReaderCreateFromVarSheetParams { 
    description?: string;
    id?: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    payment_provider_id?: string;
    processor_store_ref_id?: string;
    processor_terminal_id?: string;
    processor_terminal_ref_id?: string;
    /**
     * The provider of the terminal reader
     */
    provider: TerminalReaderCreateFromVarSheetParams.ProviderEnum;
    semi_integrated?: boolean;
    /**
     * The serial number of the terminal reader
     */
    serial_number?: string;
    settings?: Array<TerminalReaderSettings>;
    status?: TerminalReaderCreateFromVarSheetParams.StatusEnum;
    store_number?: string;
    terminal_number?: string;
    tsys_terminal_id?: string;
    /**
     * The type of card reader
     */
    type: TerminalReaderCreateFromVarSheetParams.TypeEnum;
}
export namespace TerminalReaderCreateFromVarSheetParams {
    export type ProviderEnum = 'paysafe' | 'tsys' | 'mock' | 'valor' | 'handpoint' | 'north' | 'multi_pass';
    export const ProviderEnum = {
        PAYSAFE: 'paysafe' as ProviderEnum,
        TSYS: 'tsys' as ProviderEnum,
        MOCK: 'mock' as ProviderEnum,
        VALOR: 'valor' as ProviderEnum,
        HANDPOINT: 'handpoint' as ProviderEnum,
        NORTH: 'north' as ProviderEnum,
        MULTI_PASS: 'multi_pass' as ProviderEnum
    };
    export type StatusEnum = 'created' | 'active' | 'enabled' | 'disabled';
    export const StatusEnum = {
        CREATED: 'created' as StatusEnum,
        ACTIVE: 'active' as StatusEnum,
        ENABLED: 'enabled' as StatusEnum,
        DISABLED: 'disabled' as StatusEnum
    };
    export type TypeEnum = 'vl_100' | 'vl_110' | 'vp_500' | 'a920_pro' | 'a920' | 'a80' | 'a60';
    export const TypeEnum = {
        VL_100: 'vl_100' as TypeEnum,
        VL_110: 'vl_110' as TypeEnum,
        VP_500: 'vp_500' as TypeEnum,
        A920_PRO: 'a920_pro' as TypeEnum,
        A920: 'a920' as TypeEnum,
        A80: 'a80' as TypeEnum,
        A60: 'a60' as TypeEnum
    };
}


