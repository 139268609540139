/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminDocumentTimelineEntry { 
    createdAt: string;
    status: AdminDocumentTimelineEntry.StatusEnum;
    user: string;
}
export namespace AdminDocumentTimelineEntry {
    export type StatusEnum = 'requested' | 'submitted' | 'rejected' | 'verified';
    export const StatusEnum = {
        REQUESTED: 'requested' as StatusEnum,
        SUBMITTED: 'submitted' as StatusEnum,
        REJECTED: 'rejected' as StatusEnum,
        VERIFIED: 'verified' as StatusEnum
    };
}


