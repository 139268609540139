import { AdminGetContentsOfFileRequestParams, AdminGetFileRequestParams, AdminService, ModelFile, Statement } from '@admin-api/index';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '../../../../@fuse/animations';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
} from '../../../core/constants';
import { UploadCommissionStatementDialogComponent } from './upload-commission-statement-dialog/upload-commission-statement.component-dialog';

@Component({
  selector: 'statement-list',
  styleUrls: ['./statement-list.component.scss'],
  templateUrl: './statement-list.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class StatementListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) private _paginator: MatPaginator;
  @Input() accountId: string;

  public dataSource = new MatTableDataSource<Statement>();
  public statementsCount: number = 0;
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;
  public dialogRef: any;
  errorMessage: string = null;
  isLoading: boolean = true;

  statementsTableColumns: string[] = [
    'period',
    'created_at',
    'amount',
    'link',
    'download',
  ];

  /**
   * Constructor
   */
  constructor(
    private _adminService: AdminService,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.dataSource.paginator = this._paginator;
    this.fetchStatements();
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this._paginator.page.subscribe(() => {
      this.fetchStatements();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  fetchStatements() {
    let offset = 0;
    let limit = DEFAULT_PAGE_SIZE;
    if (this._paginator) {
      offset = this._paginator.pageIndex * this._paginator.pageSize;
      limit = this._paginator.pageSize;
    }


    this.isLoading = true;
    
    this._adminService
      .adminListStatements({ accountId: this.accountId, type: Statement.TypeEnum.COMMISSION, offset, limit })
      .subscribe(
        (res) => {
          this.dataSource.data = res.items;
          this.statementsCount = res.total;
        },
        (error) => {
          this.isLoading = false;
          if (error?.error?.message) {
            this.errorMessage = 'Error: ' + error.error.message;
          } else if (error?.message) {
            this.errorMessage = 'Error: ' + error.message;
          } else {
            this.errorMessage = 'Unknown error';
          }
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  openSnackBar(message: string, action: string, warn: boolean): void {
    const snackTheme = warn ? 'mat-warn' : 'mat-primary';
    this._snackBar.open(message, action, {
      duration: 10000,
      verticalPosition: 'top',
      panelClass: ['mat-toolbar', snackTheme],
    });
  }

  downloadStatement(fileId: string) {
    let fileParams: AdminGetFileRequestParams = {
          accountId: this.accountId,
          id: fileId,
        };
    
        this._adminService.adminGetFile(fileParams).subscribe({
          next: (file) => {
            if (file) {
              let modelFile = file as ModelFile;
              let contentParams: AdminGetContentsOfFileRequestParams = {
                accountId: this.accountId,
                fileId: file.id,
              };
    
              let mimeType: string;
              switch (file.type) {
                case ModelFile.TypeEnum.PDF:
                  mimeType = 'application/pdf';
                  break;
                case ModelFile.TypeEnum.XLS:
                  mimeType = 'application/vnd.ms-excel';
                  break;
                case ModelFile.TypeEnum.XLSX:
                  mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                  break;
                default:
                  return;
              }
    
              this._adminService.adminGetContentsOfFile(contentParams).subscribe({
                next: (res) => {
                  const blob = new Blob([res], { type: mimeType });
                  const objectUrl = window.URL.createObjectURL(blob);
                  const a = document.createElement('a') as HTMLAnchorElement;
                  a.href = objectUrl;
                  a.download = modelFile.filename;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(objectUrl);
                },
                error: (err) => {
                  this._snackBar.open(
                    'Could not download file: ' +
                      err?.status +
                      ' ' +
                      err?.statusText,
                    null,
                    {
                      duration: 5000,
                    },
                  );
                },
              });
            }
          },
          error: (error) => {
            if (error?.error?.message) {
              this.errorMessage = 'Error: ' + error.error.message;
            } else if (error?.message) {
              this.errorMessage = 'Error: ' + error.message;
            } else {
              this.errorMessage = 'Unknown error';
            }
          },
          complete: () => {},
        });
  }

  public uploadCommissionStatementDialog() {
    this.dialogRef =  this._matDialog.open(UploadCommissionStatementDialogComponent, {
      data: {
        accountId: this.accountId,
      },
    });

    this.dialogRef.afterClosed().subscribe((res: UntypedFormGroup) => {
      if (!res) {
        return;
      } else {
        this.fetchStatements();
      }
    });
  }
}